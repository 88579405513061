import { Injectable, Type } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { NotifyService } from './notify.service';

@Injectable({
  providedIn: 'root'
})
export class CallhttpService {

  constructor(private httpClient: HttpClient, private configService: ConfigService, private notifyService: NotifyService) { }

  /**************************************************************/
  /*
  * METHOD: service to manage ALL POST AJAX CALL
  * INPUT: URL, DATA AND ON COMPLETE FUNCION.
  * OUTPUT: --
  */
  /*************************************************************/

  callHttpPOST(sUrl: string, aData: any, onComplete = function (aData) { }, bLoading = false) {
    if (bLoading) {
      $(".loadingScreen").show();
    }
    
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });
    
    let options = {
      headers: httpHeaders,
      withCredentials: true
    };

    this.httpClient.post<any>(sUrl, aData, options).subscribe(
      res => {
        this.analizeResponseAndCallBack(res, onComplete);
      });

  }
  
  callHttpPUT(sUrl: string, aData: any, onComplete = function (aData) {
    aData()
  }, bLoading = false) {

    if (bLoading) {
      $(".loadingScreen").show();
    }

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });

    let options = {
      headers: httpHeaders,
      withCredentials: true
    };

    this.httpClient.put<any>(sUrl, aData, options).subscribe(
      res => {
        this.analizeResponseAndCallBack(res, onComplete);
      });

  }

  /**************************************************************/
  /*
  * METHOD: service to manage ALL GET AJAX CALL
  * INPUT: URL, DATA AND ON COMPLETE FUNCION.
  * OUTPUT: --
  */
  /*************************************************************/

  callHttpGET(sUrl: string, onComplete = function (aData) { }, bLoading = false) {

    if (bLoading) {
      $(".loadingScreen").show();
    }

    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });

    let options = {
      headers: httpHeaders,
      withCredentials: true
    };

    this.httpClient.get<any>(sUrl, options).subscribe(
      res => {
        this.analizeResponseAndCallBack(res, onComplete);
      });

  }

  /**************************************************************/
  /*
  * METHOD: service to manage ALL DELETE AJAX CALL
  * INPUT: URL AND ON COMPLETE FUNCION.
  * OUTPUT: --
  */
  /*************************************************************/

  callHttpDELETE(sUrl: string, onComplete = function (aData) { }) {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    });

    let options = {
      headers: httpHeaders,
      withCredentials: true
    };

    this.httpClient.delete<any>(sUrl, options).subscribe(
      res => { this.analizeResponseAndCallBack(res, onComplete); });

  }

  /**************************************************************/
  /*
  * METHOD: service to manage ALL RESPONSES AJAX CALL
  * This method manage loading screen and notify erros in the calls.
  * INPUT: CALL RESPONSE AND ON COMPLETE FUNCION.
  * OUTPUT: --
  */
  /*************************************************************/

  analizeResponseAndCallBack(res, onComplete) {
    /* console.log('res ====> ', res); */
    $(".loadingScreen").fadeOut();
    if (res && (res.response == "OK" || res.response == true)) {
      if (res.data) {
        onComplete(res.data); 
      } else {
        onComplete(res); 
      }
    } else {
      // Manejar el caso en que la respuesta no es "OK"
      if (res && res.code) {
        this.notifyService.createNotify('error', 'Error: ' + res.code);
      } else {
        const language = localStorage.getItem('locale');
        if (language == 'es') {
          this.notifyService.createNotify('error', 'Error: ' + res.code_es);
        } else {
          this.notifyService.createNotify('error', 'Error: ' + res.code_en);
        }
      }
    }
  }
}
