import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './static/login/login.component';
import { AuthGuardService } from './core/helpers/auth.guard.service';
import { SectionStaticPricesComponent } from './static/prices/precios.component';
import { BrandtestComponent } from './brandtest/brandtest.component';
import { SectionStaticHomeComponent } from './static/home/home.component';
import { ProyectsComponent } from './proyects/proyects.component';
import { RegisterComponent } from './static/register/register.component';
import { ForgotpasswordComponent } from './static/forgotpassword/forgotpassword.component';
import { AddtestComponent } from './brandtest/addtest/addtest.component';
import { ShapeComponent } from './brandtest/results/views-results/shape/shape.component';
import { NamingComponent } from './brandtest/results/views-results/naming/naming.component';
import { LandingComponent } from './static/landing/landing.component';
import { ShoppingcartComponent } from './proyects/shoppingcart/shoppingcart.component';
import { SuccessfulpaymentComponent } from './static/successfulpayment/successfulpayment.component';
import { AccountComponent } from './account/account.component';
import { CreateReportComponent } from './brandtest/reports/create-report/create-report.component';
import { ShowReportComponent } from './brandtest/reports/show-report/show-report.component';
import { BetalandingComponent } from './static/betalanding/betalanding.component';
import { ListReportsComponent } from './proyects/list-reports/list-reports.component';
import { getTestGuardService } from './brandtest/services/gettest.service';
import { SectionStaticHowtouseComponent } from './static/howtouse/como-usa.component';
import { SectionStaticUsesCasesComponent } from './static/usecases/casos.component';
import { FailedpaymentComponent } from './static/failedpayment/failedpayment.component';
import { AnalizamosComponent } from './static/weanalyze/analizamos.component';
import { SectionStaticAboutusComponent } from './static/aboutus/aboutus.component';
import { TermsandconditionsComponent } from './static/termsandconditions/termsandconditions.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { PriceLogoGuard } from './core/helpers/guards/price-logo.guard';
import { PaymentprocessComponent } from './proyects/paymentprocess/paymentprocess.component';
import { EditprojectComponent } from './proyects/editproject/editproject.component';
import { AppointmentComponent } from './static/appointment/appointment.component';
import { SuccessfulAppointmentComponent } from './static/successful-appointment/successful-appointment.component';
import { TransactionsComponent } from './static/transactions/transactions.component';

const routes: Routes = [
  /*{ path:"", component: BetalandingComponent,
    children:[
      { path:"", component: LoginComponent, data: { page: 'five' }  },
      { path:"login", component: LoginComponent, data: { page: 'five' }  },
      { path:"register", component: RegisterComponent, data: { page: 'six' }  },
      { path:"forgotpassword", component: ForgotpasswordComponent, data: { page: 'seven' }  }
    ]
  },*/
  { path: "", component: SectionStaticHomeComponent, pathMatch: 'full' },
  { path: "howtouse", component: SectionStaticHowtouseComponent /*,canActivate: [AuthGuardService]*/ },
  { path: "usecases", component: SectionStaticUsesCasesComponent /*,canActivate: [AuthGuardService]*/ },
  { path: "weanalyze", component: AnalizamosComponent /*,canActivate: [AuthGuardService]*/ },
  { path: "weanalyze/:section", component: AnalizamosComponent/*,canActivate: [AuthGuardService]*/ },
  { path: "aboutus", component: SectionStaticAboutusComponent/*,canActivate: [AuthGuardService]*/ },
  { path: "login", component: LoginComponent, data: { page: 'five' } },
  { path: "register", component: RegisterComponent, data: { page: 'six' } },
  { path: "successfulpayment", component: SuccessfulpaymentComponent, data: { page: 'five' } },
  { path: "failedpayment", component: FailedpaymentComponent, data: { page: 'five' } },

  { path: "forgotpassword", component: ForgotpasswordComponent, data: { page: 'seven' } },

  { path: 'appointment', component: AppointmentComponent },

  {
    path: "brandtest/:id_project", component: BrandtestComponent, canActivate: [AuthGuardService],
    children: [
      { path: "name", component: NamingComponent, pathMatch: 'full', canActivate: [AuthGuardService, getTestGuardService] },
      { path: "logo", component: ShapeComponent, pathMatch: 'full', canActivate: [AuthGuardService, getTestGuardService] },
      { path: "add/:upload_type", component: AddtestComponent, canActivate: [AuthGuardService, getTestGuardService] }
    ]
  },

  { path: "create-report", component: CreateReportComponent, canActivate: [AuthGuardService], data: { page: 'two' } },
  { path: "r/:code", component: ShowReportComponent, data: { page: 'two' } },

  //{ path: "prices", component: SectionStaticPricesComponent,canActivate: [AuthGuardService], data: { page: 'two' } },
  { path: "transactions", component: TransactionsComponent,canActivate: [AuthGuardService], data: { page: 'two' } },
  { path: "shopping", component: ShoppingcartComponent, canActivate: [AuthGuardService], data: { page: 'two' } },
  { path: "confirm", component: ConfirmComponent, canActivate: [AuthGuardService], data: { page: 'two' } },
  { path: "bookingconfirmed", component: SuccessfulAppointmentComponent, data: { page: 'two' } },

  //{ path:"howthiswork", component: SectionStaticHowthisworkComponent, data: { page: 'three' }  },	
  //{ path:"aboutus", component: SectionStaticAboutusComponent, data: { page: 'four' }  },	

  /* IN ACCOUNT */
  { path: "account", component: AccountComponent, canActivate: [AuthGuardService], data: { page: 'one' } },

  { path: "projects", component: ProyectsComponent, canActivate: [AuthGuardService], data: { page: 'one' } },
  { path: "project/edit/:id_project", component: EditprojectComponent, canActivate: [AuthGuardService], data: { page: 'one' } },
  { path: "project/reports/:id_project", component: ListReportsComponent, canActivate: [AuthGuardService], data: { page: 'one' } },
  { path: "project/payment/:id_project", component: PaymentprocessComponent, canActivate: [AuthGuardService/* , PriceLogoGuard */], data: { page: 'one' } },


  // terms and conditions
  { path: "termsandconditions/:type", component: TermsandconditionsComponent, data: { page: 'two' } },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
