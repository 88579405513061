import { Component, OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit {
  private subscription: Subscription;
  public countdown: string;
  private targetTime: number;

  constructor() { }

  ngOnInit() {
     // Set the target time to 24 hours from now
     this.targetTime = Date.now() + 24 * 60 * 60 * 1000;
     this.startCountdown();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private startCountdown(): void {
    this.subscription = interval(1000).pipe(
      map(() => this.getTimeRemaining())
    ).subscribe(time => this.countdown = time);
  }

  private getTimeRemaining(): string {
    const now = Date.now();
    const distance = this.targetTime - now;

    const hours = Math.floor((distance % (1000 * 60 * 60 * 8)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  private pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }
}


 
