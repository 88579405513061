import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }


    /**************************************************************/
        /*
        * METHOD: this is a service to check if have access to view a url.
        * INPUT: --
        * OUTPUT: --
        */
    /*************************************************************/	

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        {
            if (this.authService.loggedIn) {
                // logged in so return true
                return true;
            }

            // not logged in so redirect to login page with the return url
            
            //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            this.router.navigate(['/login']);

            return false;
        }
}