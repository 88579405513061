import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../core/services/auth.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { TranslateService } from '@ngx-translate/core'; // Importa TranslateService
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifyService } from '../../../app/core/helpers/notify.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class SectionStaticHomeComponent implements OnInit {

  btnArray = [
    '../../../assets/svg/icon_bar_1.svg',
    '../../../assets/svg/icon_bar_2.svg',
    '../../../assets/svg/icon_bar_3.svg',
    '../../../assets/svg/icon_bar_4.svg',
    '../../../assets/svg/icon_bar_5.svg',
  ];

  // Variable para almacenar la referencia al modal
  private modalRef: NgbModalRef;

  // Variable para almacenar el idioma actual
  currentLanguage: string;
  request_partner_form: FormGroup;

  constructor(
    private notifyService: NotifyService,
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private httpService: CallhttpService,
    private configService: ConfigService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    // Inicializa el idioma actual
    this.currentLanguage = this.translateService.currentLang;
    // Suscríbete a los cambios de idioma
    this.translateService.onLangChange.subscribe(() => {
      this.currentLanguage = this.translateService.currentLang;
    });

    // Inicializa el FormGroup y sus controles
    this.request_partner_form = new FormGroup({
      fullname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', Validators.required),
    });
  }

  goToWeAnalize(index) {
    this.router.navigate(['/weanalyze/' + index]);
  }

  openModal(content: any) {
    this.modalRef = this.modalService.open(content, { centered: true });
  }

  // Método para enviar el formulario
  onSubmit(modal: NgbModalRef) {
    if (this.request_partner_form.valid) {
      const payload = {
        ...this.request_partner_form.value,
        "name": this.request_partner_form.value.fullname,
        "mobile_code": "+57",
        "mobile": "1234567890"
      }
      this.httpService.callHttpPOST(
        this.configService.TBT_HOST_API_DJANGO + 'contactus',
        payload,
        (aData: any) => {
          // Cerrar el modal después de enviar el formulario
          this.notifyService.createNotify('success', 'Message sent successfully');
          modal.close();
        }, true
      );

    } else {
      this.request_partner_form.markAllAsTouched();
    }
  }
}
