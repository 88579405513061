import { Component, OnInit } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ConfigService } from '../core/helpers/config.service';
import { NotifyService } from '../core/helpers/notify.service';
import { AuthService } from '../core/services/auth.service';
import { User } from '../core/services/users.service';
import { CallhttpService } from '../core/helpers/callhttp.service';
import { IfStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-proyects',
  templateUrl: './proyects.component.html',
  styleUrls: ['./proyects.component.css']
})
export class ProyectsComponent implements OnInit {

  aProjects: any = [];
  aLoginUser: User;
  bShowEmptyProjects = false
  sBoxUserClassification = ""

  bConfirmDelete = "";
  quantityValue: number = 0;
  receivedData: any;

  projects = [
    /*  {
       "project": "Trek",
       "reportLink": "https://betester.com/r/498BF07D85-1718006718-FE3E9D2F9DA9DDE6CC50CD1A4E72AB25",
       "image": "bike/trek-bicycle-corporation.jpg"
     },
     {
       "project": "Cannondale",
       "reportLink": "https://betester.com/r/498BF07D85-1718006718-D3B2667E281C56A6C8D1CB3E6388E4FC",
       "image": "bike/cannondale.jpg"
     },
     {
       "project": "Giant",
       "reportLink": "https://betester.com/r/498BF07D85-1718006718-E1B5883C05CD2252500CE656A94E645D",
       "image": "bike/giant.jpg"
     },
     {
       "project": "RockShox",
       "reportLink": "https://betester.com/r/498BF07D85-1718006718-F353CC4F5E3D02C604318DBA359A9CB7",
       "image": "bike/rock-shox.jpg"
     },
     
     {
       project: "Lufthansa",
       reportLink: "https://betester.com/r/19F7B4E6E2-1717601781-251698B7CBE7391123C275700C3942A0",
       image: "airlines/lufthansa.png"
     }, */
     {
       project: "Iberia",
       reportLink: "https://betester.com/r/19F7B4E6E2-1717601781-1D2434CED19DC2606DEE9C2AC04777AB",
       image: "airlines/iberia-airline.jpg"
     },
     {
       project: "Avianca",
       reportLink: "https://betester.com/r/19F7B4E6E2-1717601781-0E1B16DE26098D7C880D63F64A4E63CC",
       image: "airlines/avianca.jpg"
     },
     /* {
       project: "Latam",
       reportLink: "https://betester.com/r/19F7B4E6E2-1717601781-858906CABE099E42D0295AE1E9CB0AC1",
       image: "airlines/latam-airlines.jpg"
     },
     {
       project: "American Airlines",
       reportLink: "https://betester.com/r/19F7B4E6E2-1717601781-EA7AA079D3EA8241884625F45C0CDAF4",
       image: "airlines/american-airlines.jpg"
     },
     {
       project: "Bank of America",
       reportLink: "https://betester.com/r/0271293A1A-1717628610-C25D13079E3D1C2F7A780798E52E8C81",
       image: "banks/bank-of-america.jpg"
     },
     {
       project: "Santander",
       reportLink: "https://betester.com/r/0271293A1A-1717628610-4C26F9420BC0FB8B6F0866DF40B39905",
       image: "banks/santander.jpg"
     },
     {
       project: "VISA",
       reportLink: "https://betester.com/r/0271293A1A-1717628610-CAE204475C5A3502B1D7EEBC6F344985",
       image: "banks/visa.jpg"
     }, */
     {
       project: "Master Card",
       reportLink: "https://betester.com/r/0271293A1A-1717628610-AF01271A9C6FFB9735292FF79C04832D",
       image: "banks/master-card.jpg"
     },
 /*     {
       project: "Mc Donalds",
       reportLink: "https://betester.com/r/902295A7AF-1717631755-A0CE671001A08C3786FA8805B574741D",
       image: "fast_food/mcdonalds.jpg"
     }, */
     {
       project: "Burger King",
       reportLink: "https://betester.com/r/902295A7AF-1717631755-8CD8C8DF1FDAFCC1434E80C6782CA4BD",
       image: "fast_food/burgerking-New.jpg"
     },
     {
       project: "Starbucks",
       reportLink: "https://betester.com/r/902295A7AF-1717631755-C22056BF29590FCA1C214A228F22AD4F",
       image: "fast_food/starbucks.jpg"
     },
 /*     {
       project: "Subway",
       reportLink: "https://betester.com/r/902295A7AF-1717631755-9A10F358266065A6F12A48FA8A98D9B5",
       image: "fast_food/Subway.jpg"
     },
     {
       project: "Dominos",
       reportLink: "https://betester.com/r/902295A7AF-1717631755-CB5A4D93D81D2A3390B4FCAD66A73CC8",
       image: "fast_food/dominos-pizza.jpg"
     }, */
     {
       project: "KFC",
       reportLink: "https://betester.com/r/902295A7AF-1717631755-27801D59CE666C1B63FA035DDFBD2056",
       image: "fast_food/kfc.jpg"
     }
   ];
   filteredProjects: any[] = [];


  constructor(private router: Router, private httpService: CallhttpService, private notifyService: NotifyService, private configService: ConfigService, private authService: AuthService) {
    this.authService.currentUser.subscribe(x => this.aLoginUser = x);
  }


  /**************************************************************/
  /*
  * METHOD: On init this component, call all project of the user
  * INPUT: --
  * OUTPUT: --
  */
  /*************************************************************/

  ngOnInit() {
    this.getDataTestQuantity();
    let oThis = this;
    this.filteredProjects = this.projects.filter(project => project.image);

    this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'projects-getall/',
      function (aData) {
        oThis.bShowEmptyProjects = true;
        oThis.aProjects = aData;
      },
      true
    );
  }

  getDataTestQuantity() {
    const user_id = localStorage.getItem('user_id');
    const url = `${this.configService.TBT_HOST_API_DJANGO}testquantity/${user_id}`;

    this.httpService.callHttpGET(url, (data: any) => {
      if (data) {
        this.receivedData = data;
        if (this.receivedData.quantity == undefined) {
          this.quantityValue = 0
        } else {
          this.quantityValue = parseFloat(this.receivedData.quantity);
        }
      } else {
        console.error('Error: Unexpected data from the server or missing data.');
      }
    });
  }


  /**************************************************************/
  /*
  * METHOD: Click on box user clasfication, to choise 
    desgner or businessman
  * INPUT: user type: DESIGNER/BUSINESSMAN
  * OUTPUT: --
  */
  /*************************************************************/

  onClickInBoxUserClassification(sTypeUserClassified) {
    if (sTypeUserClassified == "SEND" && this.sBoxUserClassification != "") {
      let aDatos = { user_classification: this.sBoxUserClassification }
      let oThis = this;
      this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'user-set-classification/', aDatos,
        function (aData) {
          oThis.router.navigate(['/project/payment/new']);
        }
      );
    }
    else {
      this.sBoxUserClassification = sTypeUserClassified
    }
  }

  setLocalState(){localStorage.setItem('category_bar', 'shape')};


  /**************************************************************/
  /*
  * METHOD: deleteProject: Method to delete to call backend to delete project 
  * INPUT: id project
  * OUTPUT: --
  */
  /*************************************************************/

  deleteProject(nIdProject: string) {
    let oThis = this;
    if (this.bConfirmDelete == nIdProject) {
      this.httpService.callHttpDELETE(this.configService.TBT_HOST_API + 'projects-delete/' + nIdProject,
        function (aData) {
          oThis.bConfirmDelete = "";
          oThis.ngOnInit();
        }
      );
    }
    else {
      setTimeout(function () {
        oThis.bConfirmDelete = "";
      }, 2000);
    }
  }

  /**************************************************************/
  /*
  * METHOD: deleteVersioN(DEPRECATED.)
  * INPUT: --
  * OUTPUT: --
  */
  /*************************************************************/

  deleteVersion(nIdProject: string, nIdVersion: string) {
    let oThis = this;
    this.httpService.callHttpDELETE(this.configService.TBT_HOST_API + 'projects-version-delete/' + nIdProject + "/" + nIdVersion,
      function (aData) {
        oThis.ngOnInit();
      }
    );
  }

}